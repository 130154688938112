import * as React from 'react'
import { graphql } from 'gatsby'
import DefaultLayout from 'layouts/Default'
import Block from 'components/blocks/Block'

export default function page({ data: { pg } }) {
	return (
		<DefaultLayout {...pg.seo} noindex={pg.noIndex}>
			{pg.blocks?.map((block) => (
				<Block {...block} key={block?._key} />
			))}
		</DefaultLayout>
	)
}

export const query = graphql`
	query PageTemplate($id: String) {
		pg: sanityPage(id: { eq: $id }) {
			noIndex
			blocks {
				... on SanityBlockGroup {
					_key
					_type
					style
					blocks {
						...heroSimple
						...interactiveMap
						...statList
						...trustSection
					}
				}
				...blogList
				...careersList
				...cardList
				...caseStudyList
				...descriptionColumns
				...emailSignupForm
				...employeeList
				...expertiseList
				...featuresWithIcons
				...heroBlock
				...heroSimple
				...heroBgImage
				...heroQrCode
				...iconList
				...iconListDetailed
				...imageAndTextSections
				...internshipSteps
				...linkedinCta
				...logoListScroll
				...multicol
				...qrCodeList
				...servicesList
				...simpleTitle
				...testimonial
				...textWithBg
				...trustSection
				...videoBlock
				...videoLocal
			}
			seo {
				title
				description
			}
		}
	}
`
